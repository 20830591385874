import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import { awsSignIn, authData, } from "../../redux/auth/authSlice";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import ProgressBar from "../../modules/ProgressBar/ProgressBar";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import { API } from "aws-amplify";
import ClockGray from "../../assets/svgjs/ClockGray";
import UserHeader from "../Header/UserHeader/UserHeader";
import { FaBars } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import IndProgStyle from "./IndividualProgram.module.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .slick-arrow": {
      color: "black",
    },
    "& .slick-prev:before": {
      color: "revert",
      fontSize: 27,
    },
    "& .slick-next:before": {
      color: "revert",
      fontSize: 27,
    },
    "& .MuiGrid-root": {},
    "& .MuiGrid-item": {},

  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    width: "540px",
    marginLeft: "25px",
  },
}));

function IndividualProgram({ handleToggleSidebar }) {

  const [isLoading, setIsLoading] = useState(true);
  const [allCourse, setAllCourse] = useState();

  let navigate = useNavigate();

  let userDetails = useSelector(authData);
  const dispatch = useDispatch();


  useEffect(() => {
    if (userDetails?.curprg === undefined) {
      navigate("/dashboard");
    } else if (userDetails?.curprg?.tcourses != undefined) {

      getCourseDetailsForUser();
    }




  }, []);

  const classes = useStyles();
  var settingsAllPrograms = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    draggable: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,

        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  function titleclick1(data) {
    let ldata = data;
    ldata.bcid = ldata.btid;
    let pdata = userDetails.curprg;
    let sdata = { ...userDetails };

    sdata.curprgcou = { ...ldata };
    sdata.curprgcou.pid = pdata.pid;
    sdata.curprgcou.bpid = pdata.bpid;
    sdata.curprgcou.ptitle = pdata.ptitle;



    dispatch(awsSignIn(sdata));

    navigate("/course");
  }

  async function getCourseDetailsForUser() {
    setIsLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        bpid: userDetails.curprg.bpid,
        pid: userDetails.curprg.pid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      let response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_COURSE_DETAILS_FOR_USER,
        bodyParam

      );

      let allc = response.pcurriculum;
      let userCourse = userDetails.curprg.scourses;
      let tempCourse = [];

      // Loop through allc and userCourse to update properties
      for (let i = 0; i < allc.length; i++) {
        const matchingCourse = userCourse.find(
          (course) => course.bcid === allc[i].btid
        );
        if (matchingCourse) {
          allc[i].per = matchingCourse.per;
        } else {
          allc[i].per = 0;
          if (allc[i].telective) {
            allc[i].vcourse = true;
          }
        }

        allc[i].bcid = allc[i].btid;
        allc[i].semester =
          allc[i].semester === undefined ? 1 : allc[i].semester;
      }

      // Filter out courses where selected is false and telective is true
      allc = allc.filter(
        (course) =>
          !userCourse.some(
            (userCourse) =>
              userCourse.selected === false &&
              course.btid === userCourse.bcid &&
              course.telective
          )
      );

      // Filter out vcourse undefined or false
      allc = allc.filter(
        (course) => course.vcourse === undefined || course.vcourse === false
      );

      // Group courses by semester
      const groupedCourses = groupByKey(allc, "semester");

      // Push grouped courses into tempCourse array
      for (let [key, value] of Object.entries(groupedCourses)) {
        tempCourse.push(value);
      }



      let cursem =
        userDetails.curprg.currentsem == undefined
          ? 1
          : userDetails.curprg.currentsem;

      setAllCourse(tempCourse);

      console.log("tempCoursetempCourse", tempCourse)


      setIsLoading(false);
    } catch (error) {
      console.log("getCategoryError", error);
    }
  }
  function groupByKey(array, key) {
    return array.reduce((hash, obj) => {
      if (obj[key] === undefined) return hash;
      return Object.assign(hash, {
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      });
    }, {});
  }
  function titleclick(data) {

    let pdata = userDetails.curprg;
    let sdata = { ...userDetails };

    sdata.curprgcou = { ...data };
    sdata.curprgcou.pid = pdata.pid;
    sdata.curprgcou.bpid = pdata.bpid;
    sdata.curprgcou.ptitle = pdata.ptitle;



    dispatch(awsSignIn(sdata));

    navigate("/course");
  }


  function groupByKey(array, key) {
    return array.reduce((hash, obj) => {
      if (obj[key] === undefined) return hash;
      return Object.assign(hash, {
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      });
    }, {});
  }

  function semesterView() {
    return (
      <div className="container" style={{ height: "100vh" }}>
        {allCourse.map((Value, index, array) => {
          return (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {
                  userDetails.curprg.bpid == "0378079387" || userDetails.curprg.bpid == "0372847102" ?
                    <></> : <p
                      className={IndProgStyle.programsHeader}
                      style={{
                        fontWeight: "bolder",
                        fontSize: "large",
                        alignItems: "flex-start",
                        color: "black",
                      }}
                    >
                      {Value[0]?.semester
                        ? `Semester ${Value[0].semester}`
                        : `Semester ${index + 1}`}

                    </p>

                }

              </div>
              <Slider
                className={classes.root}
                {...settingsAllPrograms}

              >
                {Value.map((Value, index, array) => {
                  return (
                    <div>
                      <div
                        onClick={() => {
                          titleclick(Value);
                        }}
                      >
                        <Card
                          className={IndProgStyle.card}
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            alt=""
                            className={IndProgStyle.imageCard}
                            src={`https://${Constants.DOMAIN
                              }/${config.aws_org_id.toLowerCase()}-resources/images/topic-images/${Value.tid
                              }.png`}
                          />
                          <div className={IndProgStyle.progressbar}>
                            <ProgressBar color="orange" percent={Value.per} />
                          </div>
                          <p className={IndProgStyle.topicNameIndProg}>
                            {Value.ttitle}
                          </p>
                          <div>
                            <p style={{ marginTop: "80px" }}>
                              <span style={{ marginLeft: "10px" }}>
                                <ClockGray
                                  className={IndProgStyle.clock}
                                  cls1="cls1"
                                  cls2="cls2"
                                />
                              </span>
                              <span
                                style={{
                                  fontSize: "12px",
                                  color: "rgba(0, 0, 0, 0.6)",
                                  marginLeft: "-10px",
                                  marginBottom: "10px",
                                }}
                              >
                                {Value.tduration} weeks
                              </span>
                            </p>
                          </div>


                        </Card>
                      </div>
                    </div>
                  );
                })}
              </Slider>
              <br />
              <br />
              <br />
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <main>
      <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
        <FaBars />
      </div>
      <UserHeader />
      {isLoading ? <Skeleton style={{ height: "22rem" }} /> : semesterView()}
    </main>
  );
}
export default IndividualProgram;
