import React, { useState } from "react";
import Certificate from "../components/Certificate/Certificate";
import Aside from "../modules/SideNav/Aside";


const CertificateLayout = () => {

    const rtl = false;

    const [toggled, setToggled] = useState(false);

    const handleToggleSidebar = (value) => {
        setToggled(value);
    };
    return (
        <div className={`app ${rtl ? "rtl" : ""} ${toggled ? "toggled" : ""}`}>
            <Aside
                rtl={rtl}
                toggled={toggled}
                handleToggleSidebar={handleToggleSidebar}
            />
            <Certificate />

        </div>
    )
}

export default CertificateLayout