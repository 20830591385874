import React, { useState, useEffect, useRef } from "react";
import "./Course.scss";
import { FaBars } from "react-icons/fa";
import Tooltip from "@material-ui/core/Tooltip";
import { useSelector, useDispatch } from "react-redux";
import { setCookie } from "react-use-cookie";
import CoursePlayer from "./CoursePlayer";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import UserHeader from "../Header/UserHeader/UserHeader";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import { IconButton } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Box } from "@mui/material";
import ReactPlayer from "react-player";
import useWindowDimensions from "../../modules/Window/Window";
import QuizIc from "../../assets/svgjs/Quiz";
import PdfIcon from "../../assets/svgjs/Pdf";
import ClockGray from "../../assets/svgjs/ClockGray";
import Done from "../../assets/svgjs/Done";
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import { API } from "aws-amplify";
import References from "./References";
import { Tab as Tab1 } from "semantic-ui-react";
import Video from "../../assets/svgjs/Video";
import Audio from "../../assets/svgjs/Audio";
import Review from "../../assets/svgjs/Review";
import Html from "../../assets/svgjs/Html";
import Scrom from "../../assets/images/scorm.png";
import Discussion from "./Discussion/Discussion";
import Assessment from "../../modules/Assessment/Assessment";
import Skeleton from "@material-ui/lab/Skeleton";
import Assignment from "../../modules/Assignment/Assignment";
import { Breadcrumbs } from "@material-ui/core";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import Fullscreen from "@material-ui/icons/Fullscreen";
import screenfull from "screenfull";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import RecordSession from "./RecordSession";
import Podcast from "./Podcast";
import FeedBack from "./FeedBack";
import Project from "./Project";
import swal from "sweetalert";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: "20px",
    height: "60vh",
    overflowY: "auto",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "fit-content",
    flexShrink: 0,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  tooltipFull: {
    fontSize: 30,
  },
  bottomIcons: {
    color: "#000",
    "&:hover": {
      color: "#777",
    },
  },
  volumeButton: {
    color: "#000",
    fontSize: 40,
    transform: "scale(0.9)",
    "&:hover": {
      color: "#777",
      transform: "scale(1.03)",
      transitionTimingFunction: "cubic-bezier(0.1, 0.1, 0.25, 1)",
    },
  },
  volumeButtonExit: {
    color: "lightgray",
    fontSize: 40,
    transform: "scale(0.9)",
    "&:hover": {
      color: "#777",
      transform: "scale(1.03)",
      transitionTimingFunction: "cubic-bezier(0.1, 0.1, 0.25, 1)",
    },
  },
  videoSection: {
    padding: "2%",
  },
}));

export default function Course({ handleToggleSidebar }) {
  const classes = useStyles();
  const [courseDetails, setCourseDetails] = useState({
    tdescription: "Course Description",
  });
  const [objectData, setObjectData] = useState({});
  const [oIndex, setOIndex] = useState(0);
  const [curObject, setcurObject] = useState({});
  const curObRef = useRef({});
  const [assignment, setAssignment] = useState(false);
  const [assignmentLoad, setAssignmentLoad] = useState(true);
  const [fullscreen, setFullScreen] = useState(true);
  const [nuggets, setNuggets] = useState([]);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showPage, setShowPage] = useState(true);
  const [summative, setSummative] = useState(false);
  const [sumScore, setSumScore] = useState(false);
  const { height } = useWindowDimensions();
  const [liveopen, setLiveOpen] = useState(false);
  const [textopen, setTextOpen] = useState(false);
  const [fopen, setFOpen] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [cri, setCri] = useState("cr");
  const [spin, setSpin] = useState(false);
  const [instr, setInstr] = useState([]);
  const [cinstr, setCInstr] = useState("");
  const live_url = useRef("");
  const references = useRef("");
  const [qisLoading, setQIsLoading] = useState(false);
  const [sMiniScore, setSMiniScore] = useState(false);
  const [miniScore, setMiniScore] = useState(0);
  const [totalminiScore, setTotalMiniScore] = useState(10);
  const [feedback, setFeedback] = useState();
  const [projectNugget, setprojectNugget] = useState([]);
  const [courseUserProgress, setCourseUserProgress] = useState();
  const [projectScore, setProjectScore] = useState();
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [expandedPanel, setExpandedPanel] = useState(null);
  const full1 = useRef(null);
  const scrollRef = useRef();
  const redux = useRef({});
  const accordionRefs = useRef({});
  let userDetails = useSelector(authData);
  const dispatch = useDispatch();
  let lcourseDetails = userDetails.curprgcou;

  // Preserve scroll position when expandedPanel changes
  useEffect(() => {
    const handleScrollPreservation = () => {
      if (scrollRef.current) {
        const scrollPosition = scrollRef.current.scrollTop;
        requestAnimationFrame(() => {
          if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollPosition;
          }
        });
      }
    };

    handleScrollPreservation();
  }, [expandedPanel]);

  function toggleFullScreen() {
    screenfull.toggle(full1.current);
  }

  function onFullScreen() {
    setFullScreen(!fullscreen);
  }

  useEffect(() => {
    getCourse();
    getIns();
    reduxRestore();
  }, []);

  function reduxRestore() {
    let sdata = { ...userDetails };
    redux.current = sdata;
  }

  async function feedbackF(crir, act, resp, irdt) {
    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.eid,
        crir: crir,
        resp: resp,
        bcid: lcourseDetails.bcid,
        action: act,
        irdt: irdt,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.CIFeedBack,
        bodyParam
      );

      if (act === "get") {
        if (response.code === 1) {
          swal("Feedback already submitted");
        } else {
          setQuestions(response.q);
          setCri(crir);
          setFOpen(true);
        }
      } else {
        setCInstr("");
      }
      setSpin(false);
    } catch (error) {
      console.error(error);
    }
  }

  async function getIns() {
    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        bpid: lcourseDetails.bpid,
        bcid: lcourseDetails.bcid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.getCourseInstructor,
        bodyParam
      );

      setInstr(response);
      setSpin(false);
    } catch (error) {
      console.error(error);
    }
  }

  async function getCourse() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.eid,
        pid: lcourseDetails.pid,
        bpid: lcourseDetails.bpid,
        courseid: lcourseDetails.tid,
        bcourseid: lcourseDetails.bcid,
        ptitle: lcourseDetails.ptitle,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_COURSE,
        bodyParam
      );

      if (response != null) {
        const topicsJSON = response?.nuggets;
        function groupByKey(array, key) {
          return array.reduce((hash, obj) => {
            if (obj[key] === undefined) return hash;
            return Object.assign(hash, {
              [obj[key]]: (hash[obj[key]] || []).concat(obj),
            });
          }, {});
        }
        var result = groupByKey(topicsJSON, "unit");
        let temp = [];
        for (let i = 0; i < topicsJSON.length; i++) {
          for (let j = 0; j < topicsJSON[i].objects.length; j++) {
            temp.push(topicsJSON[i].objects[j]);
          }
        }

        setNuggets(result);
        setCourseDetails(response);
        setShowPage(response.tenabled);
        setObjectData(temp);
        saveLocale(response);
        setprojectNugget(response.nuggets);
        setCourseUserProgress(
          response.userProgressData[lcourseDetails.bcid].tp
        );
        setProjectScore(response.userProgressData[lcourseDetails.bcid].score);
        let sdata = { ...redux.current };
        dispatch(awsSignIn(sdata));
        setIsLoading(false);
      } else {
        setShowPage(false);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function syncUserProgress(userProgressData) {
    setIsLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.eid,
        pid: lcourseDetails.pid,
        bpid: lcourseDetails.bpid,
        courseid: courseDetails.tid,
        bcourseid: courseDetails.btid,
        ptitle: lcourseDetails.ptitle,
        courseProgress: userProgressData,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.SYNC_USER_PROGRESS,
        bodyParam
      );

      analyticsWebApp();
      getCourse();
      get();
    } catch (error) {
      console.error(error);
    }
  }

  async function get() {
    let userdata = { ...userDetails };

    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userdata.eid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      let response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_USER_PROGRESS,
        bodyParam
      );

      userdata.data = response;
      dispatch(awsSignIn(userdata));
    } catch (error) {
      console.error(error);
    }
  }

  async function analyticsWebApp() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        topicid: lcourseDetails.tid,
        eventtype: "Topic Subscribed",
        email: userDetails.eid,
        id: userDetails.id,
        gender: "Unknown",
        logintype: "Cognito",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.ANALYTICS_WEB_APP,
        bodyParam
      );
    } catch (error) {
      console.error("getCategoryError", error);
    }
  }

  const saveLocale = (data) => {
    const expires = new Date().getTime() + 60 * 60 * 1000;
    setCookie("CloudFront-Expires", expires);
    setCookie("CloudFront-Policy", data.Policy);
    setCookie("CloudFront-Signature", data.Signature);
    setCookie("CloudFront-Key-Pair-Id", data.KeyPairId);
  };

  const getAssignmentDataPost = async (quizid) => {
    setQIsLoading(true);
    setSMiniScore(false);
    setAssignmentLoad(true);
    setMiniScore(0);
    const bodyParam = {
      body: {
        quizid: quizid,
        oid: config.aws_org_id,
        eid: userDetails.eid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_ASSIGNMENT_DATA,
        bodyParam
      );

      var result = objectData.filter((obj) => {
        return obj.oid === quizid;
      });

      if (response.errorMessage !== undefined) {
        setAssignment(true);
      } else {
        if (response.score !== undefined) {
          setSMiniScore(true);
          setMiniScore(response.score);
        }
        setAssignment(false);
      }
      setQIsLoading(false);
      setAssignmentLoad(false);
      if (result) {
        if (
          ((result[0].qtype == 3 || result[0].qtype == "3") &&
            result[0].miniassignmenttype != undefined &&
            (result[0].miniassignmenttype == 1 ||
              result[0].miniassignmenttype == "1")) ||
          result[0].miniassignmenttype == 3 ||
          result[0].miniassignmenttype == "3" ||
          result[0].miniassignmenttype == 4 ||
          result[0].miniassignmenttype == "4"
        ) {
          let tscore = 0;
          let loop = 0;
          response.response.forEach((element) => {
            tscore += parseInt(element.qscore);
            loop++;
          });
          if (loop == response.response.length) {
            setTotalMiniScore(tscore);
          }
        } else {
          setTotalMiniScore(10);
        }
        setFeedback(response.feedback);
      }
    } catch (error) {
      console.error("getCategoryError", error);
    }
  };

  async function getQuizScore(curobj) {
    setSummative(false);
    setQIsLoading(true);
    const bodyParam = {
      body: {
        eid: userDetails.eid,
        obid: curobj.oid,
        oid: config.aws_org_id,
        rtype: "get",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.QUIZ_SCORE,
        bodyParam
      );

      if (response != undefined || response != null) {
        setSumScore(response);
        setSummative(true);
      }
      setQIsLoading(false);
    } catch (error) {
      console.error("getCategoryError", error);
    }
  }

  function CourseStructure() {
    const handleChange = (panel) => (event, isExpanded) => {
      setExpandedPanel(isExpanded ? panel : null);

      // Scroll to the accordion when expanded
      if (isExpanded && accordionRefs.current[panel]) {
        setTimeout(() => {
          accordionRefs.current[panel].scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });
        }, 300); // Increased delay to account for expansion animation
      }
    };

    return (
      <>
        {lcourseDetails?.coursetype === "Project" ? (
          <Project
            nuggets={nuggets}
            isLoading={isLoading}
            projectNugget={projectNugget}
            courseUserProgress={courseUserProgress}
            projectScore={projectScore}
          />
        ) : (
          <div className={classes.root} ref={scrollRef}>
            <Typography component="div" variant="h1">
              {isLoading ? <Skeleton /> : null}
            </Typography>
            <Typography component="div" variant="h1">
              {isLoading ? <Skeleton /> : null}
            </Typography>
            <Typography component="div" variant="h1">
              {isLoading ? <Skeleton /> : null}
            </Typography>
            <Typography component="div" variant="h1">
              {isLoading ? <Skeleton /> : null}
            </Typography>
            {Object.keys(nuggets).map((nugget, idx) => (
              <Accordion
                key={idx}
                expanded={expandedPanel === `panel${idx}`}
                onChange={handleChange(`panel${idx}`)}
                ref={(el) => (accordionRefs.current[`panel${idx}`] = el)}
                disableGutters
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${idx}bh-content`}
                  id={`panel${idx}bh-header`}
                  style={{ userSelect: "none" }} // Removed onClick handler since onChange handles it
                >
                  <Typography
                    className={classes.heading}
                    style={{ display: "flex", gap: "2rem", alignItems: "center" }}
                  >
                    <div>
                      {(lcourseDetails?.bpid === "0378079387" ||
                        lcourseDetails?.bpid === "0372847102") && (
                          <div>
                            <img
                              alt=""
                              style={{
                                height: "8rem",
                                width: "8rem",
                                objectFit: "contain",
                                marginLeft: "2rem",
                              }}
                              src={`https://${Constants.DOMAIN}/${config.aws_org_id.toLowerCase()}-resources/images/unit-images/${courseDetails.tid}/${idx}.png`}
                              onError={(e) => (e.target.style.display = "none")}
                            />
                          </div>
                        )}
                    </div>
                    <h2 className="size-m-b">{nugget}</h2>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {nuggets[nugget].map(
                      (
                        { nid, unit, ntitle, nduration, objects, nenabled },
                        idx
                      ) => (
                        <div key={idx}>
                          <div className="display-flex padding2">
                            <h3 className="item" style={{ fontWeight: "bold" }}>
                              {ntitle}
                            </h3>
                            <p style={{ fontSize: "14px" }}>
                              <span>
                                <ClockGray
                                  className="clock-size--s"
                                  cls1="cls1-s"
                                  cls2="cls2-s"
                                />
                              </span>
                              {nduration} Minutes
                            </p>
                          </div>
                          {lcourseDetails?.coursetype !== "Project" &&
                            objects.map(({ oid, otitle, otype, userOP }) => (
                              <div
                                key={oid}
                                className="flex size"
                                style={{
                                  justifyContent: "space-between",
                                  width: "calc(100% - 4.5%)",
                                }}
                              >
                                <p style={{ cursor: "pointer" }}>
                                  <span>
                                    {userOP.op === 2 || userOP.op === 1
                                      ? renderSwitch(otype)
                                      : renderSwitchGray(otype)}
                                  </span>
                                  <a
                                    style={{ paddingLeft: "10px" }}
                                    href
                                    onClick={(e) => {
                                      e.preventDefault();
                                      onClickObject(oid);
                                    }}
                                  >
                                    {otitle}
                                  </a>
                                </p>
                                <span>
                                  {userOP.op === 2 ? (
                                    <Done
                                      className="Done_size"
                                      cls1="cls1_D"
                                      cls2="cls2_D"
                                    />
                                  ) : null}
                                </span>
                              </div>
                            ))}
                        </div>
                      )
                    )}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
            {isLoading ? (
              <Skeleton />
            ) : (["1", 1, "2", 2].includes(courseDetails.userTP) &&
              !["0378079387", "0372847102"].includes(lcourseDetails.bpid)) ? (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.heading}>
                    <h2 className="size-m-b">Instructor Feedback</h2>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {instr.map((item, i) => {
                    return (
                      <h3
                        className="item"
                        style={{
                          fontWeight: "bold",
                          padding: "4px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          feedbackF("ir", "get", "", item);
                          setCInstr(item);
                        }}
                      >
                        {i + 1}. {item.name}
                      </h3>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            ) : null}
            {isLoading ? (
              <Skeleton />
            ) : (
              <Box display="flex" p={1}>
                {(["1", 1, "2", 2].includes(courseDetails.userTP) &&
                  !["0378079387", "0372847102"].includes(lcourseDetails.bpid)) ? (
                  <Box p={1} flexGrow={1}>
                    <Typography
                      className={classes.heading}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        feedbackF("cr", "get");
                        setCInstr("");
                      }}
                    >
                      <h2 className="size-m-b">Course Feedback</h2>
                    </Typography>
                  </Box>
                ) : null}
              </Box>
            )}
          </div>
        )}
      </>
    );
  }

  function onClickObject(oid) {
    let temp = 0;
    let targetPanel = null;

    // Find the object index and corresponding accordion panel
    for (let i = 0; i < objectData.length; i++) {
      if (oid === objectData[i].oid) {
        temp = i;
        Object.keys(nuggets).forEach((nugget, idx) => {
          const found = nuggets[nugget].some((topic) =>
            topic.objects.some((obj) => obj.oid === oid)
          );
          if (found) {
            targetPanel = `panel${idx}`;
          }
        });
        break;
      }
    }

    // Set the expanded panel and scroll to it
    setExpandedPanel(targetPanel);
    setOIndex(temp);
    setcurObject(objectData[temp]);

    // Handle quiz-related logic
    if (objectData[temp].otype === "quiz") {
      getAssignmentDataPost(objectData[temp].oid);
      getQuizScore(objectData[temp]);
    }
    curObRef.current = objectData[temp];

    // Open the modal if the course has started
    if (courseDetails.userTP !== 0) {
      setOpen(true);
    }

    // Scroll to the expanded accordion after a slight delay
    if (targetPanel && accordionRefs.current[targetPanel]) {
      setTimeout(() => {
        accordionRefs.current[targetPanel].scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }, 300); // Increased delay to account for expansion animation
    }
  }

  const handleClose = () => {
    getCourse(); // Refresh course data
    setOpen(false); // Close the modal

    // Restore the expanded panel and scroll to it after modal close
    const currentOid = curObRef.current.oid;
    let targetPanel = null;

    Object.keys(nuggets).forEach((nugget, idx) => {
      const found = nuggets[nugget].some((topic) =>
        topic.objects.some((obj) => obj.oid === currentOid)
      );
      if (found) {
        targetPanel = `panel${idx}`;
      }
    });

    if (targetPanel) {
      setExpandedPanel(targetPanel); // Expand the accordion
      setTimeout(() => {
        if (accordionRefs.current[targetPanel]) {
          accordionRefs.current[targetPanel].scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });
        }
      }, 300); // Increased delay to account for expansion animation
    }
  };

  const handleLiveClose = () => {
    setLiveOpen(false);
  };

  const handleTextClose = () => {
    setTextOpen(false);
  };

  const renderSwitch = (param) => {
    if (param === "video") {
      return <Video className="video-size" cls1="cls-1--v" cls2="cls-2--v" />;
    } else if (param === "audio") {
      return <Audio className="video-size" cls1="cls-1--v" cls2="cls-2--v" />;
    } else if (param === "quiz") {
      return <QuizIc className="quizAct" />;
    } else if (param === "html") {
      return (
        <Html
          className="video-size"
          cls1="cls-1--H"
          cls2="cls-2--H"
          cls3="cls-3--H"
        />
      );
    } else if (param === "Interactivity" || param === "interactivity") {
      return <Review className="video-size" cls1="cls-1--R" cls2="cls-2--R" />;
    } else if (param === "pdf") {
      return <PdfIcon className="pdfAct" />;
    } else if (param === "scorm") {
      return <img src={Scrom} style={{ height: "32px" }} alt="" />;
    }
  };

  const renderSwitchGray = (param) => {
    if (param === "video") {
      return (
        <Video className="video-size" cls1="cls-1--v-g" cls2="cls-2--v-g" />
      );
    } else if (param === "audio") {
      return (
        <Audio className="video-size" cls1="cls-1--v-g" cls2="cls-2--v-g" />
      );
    } else if (param === "quiz") {
      return <QuizIc className="quizdark" />;
    } else if (param === "html") {
      return (
        <Html
          className="video-size"
          cls1="cls-1--H-g"
          cls2="cls-2--H-g"
          cls3="cls-3--H-g"
        />
      );
    } else if (param === "Interactivity" || param === "interactivity") {
      return (
        <Review className="video-size" cls1="cls-1--R-g" cls2="cls-2--R-g" />
      );
    } else if (param === "scorm") {
      return <img src={Scrom} style={{ height: "32px" }} alt="" />;
    } else if (param === "pdf") {
      return <PdfIcon className="pdfGray" />;
    }
  };

  var panes = [
    {
      menuItem: "Course structure",
      render: () => (
        <Tab1.Pane>
          <CourseStructure />
        </Tab1.Pane>
      ),
    },
    {
      menuItem: "Discussion",
      render: () => (
        <Tab1.Pane>
          <Discussion courseId={lcourseDetails.bcid} />
        </Tab1.Pane>
      ),
    },
    {
      menuItem: "Assignment",
      render: () => (
        <Tab1.Pane>
          <Assignment
            courseId={lcourseDetails.bcid}
            progId={lcourseDetails.bpid}
          />
        </Tab1.Pane>
      ),
    },
    {
      menuItem: "Assessment",
      render: () => (
        <Tab1.Pane>
          <Assessment
            courseId={lcourseDetails.bcid}
            progId={lcourseDetails.bpid}
          />
        </Tab1.Pane>
      ),
    },
    {
      menuItem: "Recorded session",
      render: () => (
        <Tab1.Pane>
          <RecordSession />
        </Tab1.Pane>
      ),
    },
    {
      menuItem: "Podcast",
      render: () => (
        <Tab1.Pane>
          <Podcast
            pid={lcourseDetails.pid}
            bpid={lcourseDetails.bpid}
            courseid={lcourseDetails.tid}
            bcourseid={lcourseDetails.bcid}
          />
        </Tab1.Pane>
      ),
    },
    {
      menuItem: "References",
      render: () => (
        <Tab1.Pane>
          <References />
        </Tab1.Pane>
      ),
    },
  ];

  if (lcourseDetails?.coursetype === "Project") {
    panes = panes.filter(
      (pane) =>
        pane.menuItem === "Course structure" ||
        pane.menuItem === "Recorded session"
    );
  }

  function VideoView() {
    return (
      <div style={{ display: "flex", height: "78%", backgroundColor: "white" }}>
        <div style={{ height: "100%", width: "100%" }}>
          <div style={{ width: "100%", height: "26px" }}>
            <span
              style={{
                float: "right",
                cursor: "pointer",
                color: "black",
                fontSize: "26px",
                paddingRight: "20px",
              }}
              onClick={() => {
                handleLiveClose();
              }}
            >
              x
            </span>
          </div>
          <div className={classes.videoSection}>
            <div
              className="player-wrapper"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <ReactPlayer
                url={live_url.current}
                controls={true}
                width="100%"
                height={height - 300}
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  function TextBookView() {
    let url = `https://${Constants.DOMAIN}/${config.aws_org_id.toLowerCase()}-resources/references/${references.current.url}`;

    return (
      <div
        style={{
          display: "flex",
          height: "78%",
          backgroundColor: "white",
        }}
      >
        <div style={{ height: "100%", width: "100%" }}>
          <div style={{ width: "100%", height: "26px" }}>
            <span
              style={{
                float: "right",
                cursor: "pointer",
                color: "black",
                fontSize: "26px",
                paddingRight: "20px",
              }}
              onClick={() => {
                handleTextClose();
              }}
            >
              x
            </span>
          </div>
          <div className={classes.videoSection}>
            <div
              className="player-wrapper"
              style={{
                display: "flex",
                justifyContent: "center",
                height: "inherit",
                position: "relative",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                  overflowX: "hidden",
                }}
              >
                <iframe src={url} height="100%" width="100%" title="textbook"></iframe>
              </div>
              <IconButton
                style={{ display: "flex", float: "right", padding: 0 }}
                onClick={() => {
                  toggleFullScreen();
                  onFullScreen();
                }}
                className={classes.bottomIcons}
              >
                {fullscreen ? (
                  <Tooltip
                    title={<p style={{ fontSize: "13px" }}>Click here for full-screen mode</p>}
                    placement="top"
                  >
                    <Fullscreen
                      fontSize="large"
                      className={classes.volumeButton}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={<p style={{ fontSize: "13px" }}>Click here for exit full-screen mode</p>}
                    placement="top"
                  >
                    <FullscreenExitIcon
                      fontSize="large"
                      className={classes.volumeButtonExit}
                      style={{ bottom: "200px" }}
                    />
                  </Tooltip>
                )}
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderButton() {
    if (courseDetails.userTP === 0) {
      return (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "50px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            style={{
              color: "white",
              borderStyle: "groove",
              borderColor: "#E35F14",
              height: "30px",
              lineHeight: "0px",
              width: "150px",
              backgroundColor: "#E35F14",
              fontSize: "16px",
              borderRadius: "3px",
              fontWeight: "bold",
            }}
            onClick={startCourse}
          >
            Start
          </button>
        </div>
      );
    }
  }

  function startCourse() {
    let data = courseDetails.userProgressData;
    data[courseDetails.btid].tp = 1;
    data[courseDetails.btid].td.sd = Math.round(new Date().getTime() / 1000);
    data[courseDetails.btid].objects[courseDetails.nuggets[0].objects[0].oid].op = 1;
    syncUserProgress(data);
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        padding: "25px",
        overflowY: "auto",
      }}
      ref={scrollRef}
    >
      {spin === true ? (
        <div className={"spinLoad"}>
          <CircularProgress
            style={{
              position: "fixed",
              top: "48%",
              left: "48%",
              background: "transparent",
              zIndex: "2001",
            }}
          />
        </div>
      ) : null}
      <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
        <FaBars />
      </div>
      <UserHeader />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="main-dialog">
            <CoursePlayer
              totalminiScore={totalminiScore}
              handleClose={handleClose}
              courseDetails={courseDetails}
              setCourseDetails={setCourseDetails}
              objectData={objectData}
              setObjectData={setObjectData}
              oIndex={oIndex}
              setOIndex={setOIndex}
              curObject={curObject}
              setcurObject={setcurObject}
              curObRef={curObRef}
              assignment={assignment}
              setAssignment={setAssignment}
              summative={summative}
              setSummative={setSummative}
              sumScore={sumScore}
              setSumScore={setSumScore}
              miniScore={miniScore}
              setMiniScore={setMiniScore}
              sMiniScore={sMiniScore}
              setSMiniScore={setSMiniScore}
              qisLoading={qisLoading}
              setQIsLoading={setQIsLoading}
              getQuizScore={getQuizScore}
              getAssignmentDataPost={getAssignmentDataPost}
              assignmentLoad={assignmentLoad}
              setAssignmentLoad={setAssignmentLoad}
              feedback={feedback}
              setFeedback={setFeedback}
              feedbackF={feedbackF}
              setCInstr={setCInstr}
              instr={instr}
              setFOpen={setFOpen}
              questions={questions}
              cinstr={cinstr}
              cri={cri}
              showCommentBox={showCommentBox}
              lcourseDetails={lcourseDetails}
              setQuestions={setQuestions}
              setShowCommentBox={setShowCommentBox}
              setCri={setCri}
              open1={open}
              setOpen1={setOpen}
            />
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={liveopen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={liveopen}>
          <VideoView />
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={textopen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        ref={full1}
      >
        <Fade in={textopen}>
          <TextBookView />
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={fopen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={fopen}>
          <>
            <FeedBack
              close={setFOpen}
              Questions={questions}
              cri={cri}
              feedbackF={feedbackF}
              cinstr={cinstr}
            />
          </>
        </Fade>
      </Modal>

      <div className="text-align">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="large" style={{ fill: "#1974b5" }} />}
          aria-label="breadcrumb"
          style={{ marginLeft: "2rem", marginBottom: "3rem" }}
        ></Breadcrumbs>
      </div>
      {showPage === true || showPage === "true" ? (
        <div className="management">
          <div className="h1">
            <h1 style={{ color: "#E35F14", fontSize: "20px" }}>
              <strong>{isLoading ? <Skeleton /> : courseDetails.ttitle}</strong>
            </h1>
          </div>
          <div className="management-info">
            <div className="flex-box">
              <div className="flex-box__container">
                <div className="time-line">
                  <div>
                    <span>
                      <ClockGray
                        className="clock-size"
                        cls1="cls1"
                        cls2="cls2"
                      />
                    </span>
                    <p>{isLoading ? <Skeleton /> : courseDetails.tduration} weeks</p>
                  </div>
                </div>
                <div>
                  <p
                    style={{
                      fontSize: "15px",
                      maxHeight: "18vh",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <p
                      dangerouslySetInnerHTML={{
                        __html: courseDetails.tdescription,
                      }}
                    ></p>
                  </p>
                  {isLoading ? <Skeleton /> : renderButton()}
                </div>
              </div>
              {isLoading ? (
                <Skeleton variant="rect" width="100%">
                  <div style={{ paddingTop: "57%" }} />
                </Skeleton>
              ) : (
                <div
                  className="management__image"
                  style={{
                    backgroundImage: `url('https://${Constants.DOMAIN}/${config.aws_org_id.toLowerCase()}-resources/images/topic-images/${courseDetails.tid}.png')`,
                  }}
                ></div>
              )}
            </div>
          </div>

          <Tab1 panes={panes} className="tab_section" />
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1>The Course Is Disabled From Admin.</h1>
        </div>
      )}
    </div>
  );
}