import React, { useEffect, useState } from "react";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import UserHeader from "../Header/UserHeader/UserHeader";
import "../HomeScreen/Dashboard/dashboard.scss";
import { FaBars } from "react-icons/fa";
import {
    Button, styled, Dialog, DialogTitle, DialogContent,
    IconButton, Typography, DialogActions
} from '@mui/material';
import Certificate1 from "../../assets/images/Certificate1.jpg";
import Certificate2 from "../../assets/images/Certificate2.jpg";
import { Link, useNavigate } from "react-router-dom";
import User from "./UserProgress.module.scss";
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as RibbonIcon } from '../../assets/svg/Ribbon.svg';
import config from "../../config/aws-exports";
import { API } from "aws-amplify";
import { Constants } from "../../config/constants";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .slick-arrow": {
            color: "black",
        },
        "& .slick-prev:before": {
            color: "black",
            fontSize: 27,
        },
        "& .slick-next:before": {
            color: "black",
            fontSize: 27,
        },
        "& .MuiGrid-root": {},
        "& .MuiGrid-item": {},
        "& .MuiGrid-grid-xs-6": {},
    },
    mainGrid: {
        width: "100%",
        margin: 0,
        "& > div": {
            padding: "15px!important",
        },
    },
    AssessmentpieChartStyle: {
        "& > div ": {
            overflow: "auto",
        },
        "& > div > div > div > div > svg": {
            width: "100vw!important",
        },
    },
    IDButton: {
        border: "1px solid var(--sub-heading-color) !important", color: "var(--sub-heading-color) !important",
        textTransform: "capitalize!important", gap: "1rem", fontSize: "1.4rem!important", width: "10rem", marginLeft: "auto!important",
    },
    joinNowButton: {
        backgroundColor: "var(--sub-heading-color) !important",
        textTransform: "capitalize!important",
        width: "10rem",
        height: "40px",
        fontSize: "1.5rem!important",
        border: "none",
        color: "white!important",
        borderRadius: "4px",
    },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const Certificate = (handleToggleSidebar) => {

    let userDetails = useSelector(authData);
    let navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {

        getUsersDetails();
    }, []);

    const classes = useStyles();

    const [openItem, setOpenItem] = React.useState(false);
    const [certificate, setCertificate] = React.useState([]);
    const [currentFile, setCurrentFile] = React.useState();
    const [loading, setLoading] = React.useState(false);


    const handleClickOpenItem = () => {
        setOpenItem(true);
    };
    const handleCloseItem = () => {
        setOpenItem(false);
    };

    async function getUsersDetails() {
        const bodyParam = {
            body: {
                oid: config.aws_org_id,
                eid: userDetails.eid,
                email: userDetails.email,
            },
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };

        setLoading(true)
        try {
            const response = await API.post(
                config.aws_cloud_logic_custom_name,
                "/getUserProfile",
                bodyParam
                //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
            );
            const users = response;
            console.log("uresponse", users[0].CERTIFICATE);
            setCertificate(users[0].CERTIFICATE)

        }

        catch (error) {
            console.log("getUserError", error);
        }

        setLoading(false)
    }

    // const handleDownload = async (cert) => {
    //     const url = `https://${Constants.DOMAIN}/${config.aws_org_id.toLowerCase()}-data/data/certificates/${userDetails.email}/${cert}`

    //     console.log("url", url)

    //     try {
    //         const response = await fetch(url, { method: 'GET', mode: 'cors' });

    //         if (!response.ok) {
    //             throw new Error(`Download failed: ${response.statusText}`);
    //         }

    //         const blob = await response.blob();
    //         const link = document.createElement('a');
    //         const blobUrl = URL.createObjectURL(blob);

    //         link.href = blobUrl;
    //         link.download = 'certificate.png';
    //         link.click();

    //         URL.revokeObjectURL(blobUrl);
    //     } catch (error) {
    //         console.error('Error downloading file:', error);
    //     }
    // };

    const handleDownload = async (file) => {
        const url = `https://${Constants.DOMAIN}/${config.aws_org_id.toLowerCase()}-data/userdata/${userDetails.eid}/certificate/${file}`;

        try {
            const response = await fetch(url, { method: 'GET', mode: 'cors' });

            if (!response.ok) {
                throw new Error(`Download failed: ${response.statusText}`);
            }

            const blob = await response.blob();
            const link = document.createElement('a');
            const blobUrl = URL.createObjectURL(blob);

            link.href = blobUrl;
            link.download = 'certificate.png';
            link.click();

            URL.revokeObjectURL(blobUrl);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    function programlist() {
        let data = [];

        if (userDetails.data != undefined) {
            if (userDetails.data.bpdata != undefined) {
                data = [...userDetails.data.bpdata];
            }
        }

        return (
            <div style={{ display: "flex", gap: "12rem", padding: "5rem", flexWrap: "wrap" }}>

                {certificate == undefined || certificate.length == 0 ? (<div style={{ fontWeight: "bold", }}>No Data</div>) : (<> {certificate.map((cert) => (

                    <div className={User.mainsection} style={{ width: "40%", position: "relative", }}>
                        <div style={{ border: "3px solid var(--sub-heading-color)", borderRadius: "3px", height: "43rem", overflow: "hidden", paddingTop: "4rem" }}>
                            <img src={`https://${Constants.DOMAIN}/${config.aws_org_id.toLowerCase()}-data/data/certificates/${userDetails.email}/${cert}`} alt="certificate" />
                        </div>

                        <div className={User.field} style={{ justifyContent: "space-between" }}>
                            <div className={User.title}>
                                <Button className={classes.IDButton} onClick={() => {
                                    setCurrentFile(`https://${Constants.DOMAIN}/${config.aws_org_id.toLowerCase()}-data/data/certificates/${userDetails.email}/${cert}`);
                                    handleClickOpenItem();
                                }}>
                                    Preview
                                </Button>
                            </div>
                            <div className={User.title}>
                                <Button autoFocus className={classes.joinNowButton} onClick={() => {
                                    handleDownload(cert);
                                }}>
                                    Download
                                </Button>
                            </div>
                        </div>
                        {/* <div style={{ position: "absolute", top: 0, left: "-12%" }}>
        <RibbonIcon style={{ width: "111%" }} />
    </div>
    <div style={{ position: "absolute", top: "3.5rem", left: "2%", width: "96%", color: "#fff", textAlign: "center" }}>
        <p style={{ fontWeight: "600", fontSize: "1.5rem" }}>PG DIPLOMA IN BIOINFORMATICS</p>
    </div> */}


                        <React.Fragment>
                            <BootstrapDialog
                                onClose={handleCloseItem}
                                aria-labelledby="customized-dialog-title"
                                open={openItem}
                                sx={{
                                    "& > div > div": {
                                        width: "100%", maxWidth: "80%", backgroundColor: "#efefef",
                                        "@media (max-width: 1025px)": {
                                            maxWidth: "100%", margin: "2rem"
                                        },
                                    },
                                }}
                            >
                                <DialogTitle sx={{ m: 0, p: 2, fontSize: "2rem!important" }} id="customized-dialog-title">
                                    {cert}
                                </DialogTitle>
                                <IconButton
                                    aria-label="close"
                                    onClick={handleCloseItem}
                                    style={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: (theme) => theme.palette.grey[500],
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                                <DialogContent style={{ padding: "2rem" }}>
                                    <Typography style={{ display: "flex", justifyContent: "center", gap: "1rem" }}>
                                        <img src={currentFile} alt="certificate" style={{ width: "45%" }} />

                                    </Typography>
                                </DialogContent>
                                <DialogActions>
                                    <Button autoFocus onClick={() => { handleDownload(cert) }} className={classes.joinNowButton}>
                                        Download
                                    </Button>
                                </DialogActions>
                            </BootstrapDialog>
                        </React.Fragment>
                    </div>


                ))}</>)
                }

            </div>

        );
    }
    return (
        <main>
            <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
                <FaBars />
            </div>
            <div>

                <UserHeader />
                {loading === true ? <div className={{
                    position: "fixed",
                    backgroundColor: "#000000a1",
                    width: "100vw",
                    height: "100vh",
                    display: "flex",
                    top: "0",
                    left: "0",
                    zIndex: "2000",
                }}><CircularProgress style={{ position: "fixed", top: "48%", left: "48%", background: "transparent", zIndex: "2001" }} /> </div> : <div style={{ height: "100vh" }}>
                    <h2 style={{ fontWeight: "bold", paddingLeft: "5rem", }}>Certificates</h2>
                    <div className={classes.root}>{programlist()}</div>
                </div>}
            </div>
        </main>
    )
}

export default Certificate