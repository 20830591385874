import { React, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import UserHeader from "../Header/UserHeader/UserHeader";
import { Avatar, Typography } from "@material-ui/core";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DownloadIcon from "@mui/icons-material/Download";
import { makeStyles } from "@material-ui/styles";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import InputAdornment from "@mui/material/InputAdornment";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import Modal from "@material-ui/core/Modal";
import { Skeleton } from "@material-ui/lab";
import Fade from "@material-ui/core/Fade";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { saveAs } from "file-saver";
import { FaBars } from "react-icons/fa";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { API } from "aws-amplify";
import moment from "moment";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import axios from "axios";
import swal from "sweetalert";
import ViewDoc from "./ViewDoc";
import DownloadDocs from "./DownloadDocs";
import { CloseFullscreen } from "@mui/icons-material";

const useStyles = makeStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  formContainer: {
    margin: "auto",
    marginTop: "30px",
    width: "70%",
    "@media (max-width: 940px)": {
      width: "100%",
    },
  },
  nameSection: {
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 500px)": {
      display: "block",
      "& > div:nth-child(2)": {
        marginTop: "2rem",
      },
    },
  },
  responsiveFieldSection: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
    "@media (max-width: 500px)": {
      display: "block",
      "& > div:nth-child(2)": {
        marginTop: "2rem",
      },
    },
  },
  inputFieldName: {
    border: "none",
    height: "25px",
    boxShadow: "0px 0px 5px 0px lightgrey ",
    fontSize: "15px",
    fontWeight: "bold",
    padding: "5px",
    "@media (max-width: 500px)": {
      width: "100%",
    },
  },
  textFieldNormal: {
    border: "none",
    height: "25px",
    width: "100%",
    boxShadow: "0px 0px 5px 0px lightgrey ",
    fontSize: "15px",
    fontWeight: "bold",
    padding: "5px",
  },
});

function Profile({ handleToggleSidebar }) {
  // export default function ApplyProgramInfo({ handleToggleSidebar }) {
  let userDetails = useSelector(authData);
  console.log(userDetails);
  const refselectedCourseFile = useRef();
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [address, setAddress] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [dob, setDob] = useState("");
  const [passportNo, setPassportNo] = useState("");
  const [govtId, setGovtId] = useState("");
  const [higestDegree, setHigestDegree] = useState("");
  const [workExperience, setWorkExperience] = useState("");
  const [userNameErr, setUserNameErr] = useState("");
  const [pissueTextUpload, setPIssueTextUpload] = useState("");
  const [spin, setSpin] = useState(false);
  const [profilePicName, setProfilePicName] = useState("");
  const [docList, setDocList] = useState([]);
  const [openAdd, setOpenAdd] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [marksFile, setMarkFile] = useState([]);
  const [certificateFile, setCertificateFile] = useState([]);
  const [uploadType, setUploadType] = useState();
  const [openDelete, setopenDelete] = useState(false);
  const [deleteDocs, setDeleteDocs] = useState(false);
  const [openDownload, setopenDownload] = useState(false);
  const [cookies, setCookies] = useState("");
  const [selectedCurlFile, setSelectedCurlFile] = useState("");
  const classes = useStyles();
  const [usn, setUsn] = useState([]);

  const [pgm, setPgm] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [profile, setprofile] = useState({
    profilePhoto: "",
    Document: "",
  });

  const handleAddOpen = () => {
    setOpenAdd(true);
  };

  const handleAddClose = () => {
    setOpenAdd(false);
  };

  const handledeleteOpen = () => {
    setopenDelete(true);
  };

  const handleDeleteClose = () => {
    setopenDelete(false);
  };

  const handleDownloadOpen = () => {
    setopenDownload(true);
  };

  const handleDownloadClose = () => {
    setopenDownload(false);
  };
  const onChangeHandlerUser = (event) => {
    setFirstName(event.target.value);
  };

  const onChangeHandlerLastName = (event) => {
    setLastName(event.target.value);
  };

  const onChangePhoneNo = (event) => {
    setPhoneNo(event.target.value);
  };

  const onChangeHandlerAddress = (event) => {
    setAddress(event.target.value);
  };

  const onChangeHandlerCountryCode = (event) => {
    setCountryCode(event.target.value);
  };

  const onChangeHandlerDob = (event) => {
    setDob(event.target.value);
  };

  const onChangeHandlerPassportNo = (event) => {
    setPassportNo(event.target.value);
  };

  const onChangeHandlerGovtId = (event) => {
    setGovtId(event.target.value);
  };

  const onChangeHandlerHigestDegree = (event) => {
    setHigestDegree(event.target.value);
  };

  const onChangeHandlerWorkExperience = (event) => {
    setWorkExperience(event.target.value);
  };
  useEffect(() => {
    getUsersDetails();
    getUsn();
    // console.log("UploadType", uploadType);
  }, []);

  async function handleProfilePhoto(e) {
    setPIssueTextUpload("");
    if (e.target.files != undefined) {
      console.log("inside");
      let selectedFile = e.target.files[0];
      let extend =
        selectedFile.name.substring(
          selectedFile.name.lastIndexOf(".") + 1,
          selectedFile.name.length
        ) || selectedFile.name;
      console.log(e.target.files[0].size, "inside123", extend);
      let fileUploadType = "profiledetails";
      if (e.target.files[0].size < 50 * 1024) {
        setPIssueTextUpload("file size is less than 50kb");
        return;
      } else if (e.target.files[0].size > 1024 * 1024) {
        setPIssueTextUpload("file size is greater than 1024kb");
        return;
      } else if (
        extend == "JPG" ||
        extend == "PNG" ||
        extend == "JPEG" ||
        extend == "jpg" ||
        extend == "png" ||
        extend == "jpeg"
      ) {
        let profilPhotoFile = e.target.files[0];
        setProfilePicName(profilPhotoFile.name);
        refselectedCourseFile.current = profilPhotoFile;
        uploadDocsToServer(profilPhotoFile, fileUploadType);
        console.log("profilPhotoFile", profilPhotoFile.name);
      } else {
        setPIssueTextUpload(
          "Profile photo Only images with .jpg, .jpeg, .png extension are allowed."
        );
        return;
      }
    }
  }

  async function handleProfileDocument(e) {
    setPIssueTextUpload("");
    if (e.target.files !== undefined && e.target.files[0]) {
      setPIssueTextUpload("");
      let selectedFile = e.target.files[0];
      let extend =
        selectedFile.name.substring(
          selectedFile.name.lastIndexOf(".") + 1,
          selectedFile.name.length
        ) || selectedFile.name;
      console.log(e.target.files[0].size, "inside123", 250 * 1024);
      let fileUploadType = "profiledocs";
      if (e.target.files[0].size < 50 * 1024) {
        setPIssueTextUpload("file size is less than 50kb");
        return;
      } else if (e.target.files[0].size > 250 * 1024) {
        setPIssueTextUpload("file size is greater than 1mb");
        return;
      } else if (
        extend == "JPG" ||
        extend == "PNG" ||
        extend == "JPEG" ||
        extend == "jpg" ||
        extend == "png" ||
        extend == "jpeg"
      ) {
        console.log("extendmatch==", e.target.files);
        console.log("h3", e.target.files[0]);
      } else {
        let file = e.target.files[0];
        if (docList !== undefined && docList.length > 0) {
          setDocList((docList) => [...docList, file.name]);
        } else {
          setDocList([file.name]);
        }

        uploadDocsToServer(file, fileUploadType);
        console.log("file Name", file.name);
      }
    }
  }

  async function uploadDocsToServer(file, fileUploadType) {
    setSpin(true);

    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.eid,
        type: fileUploadType,
        filetype: file.type,
        filename: file.name,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      let response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PRESIGNED_URL,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );
      console.log(" bodyParam", bodyParam);

      fileUpload(file, response, fileUploadType);
    } catch (error) {
      setSpin(false);
      console.log("getCategoryError", error);
    }
  }

  async function fileUpload(file, url, fileUploadType) {
    setSpin(true);
    await axios
      .put(url, file, { headers: { "Content-Type": file.type } })
      .then((res) => {
        console.log("fileUploadType", fileUploadType);
        console.log(profile, "fileUpload==", res);
        setSpin(false);
      })
      .catch((err) => {
        console.error(err);
        setSpin(false);
      });
  }

  const onsubmit = () => {
    setUserNameErr("");
    let formIsValid = true;

    if (profilePicName === "" || profilePicName === null) {
      formIsValid = false;
      setUserNameErr("Please Select Profile Photo");
      return;
    }

    if (
      firstName === "" ||
      firstName === null ||
      (firstName != undefined && firstName.trim() === "")
    ) {
      formIsValid = false;
      setUserNameErr("Please enter user name");
      return;
    }

    if (dob === undefined || dob === "") {
      formIsValid = false;
      setUserNameErr("Please enter date of Birth");
      return;
    }
    if (countryCode === undefined || countryCode === "") {
      formIsValid = false;
      setUserNameErr("Please enter country code");
      return;
    }

    if (
      address === undefined ||
      address === "" ||
      (address != undefined && address.trim() === "")
    ) {
      formIsValid = false;
      setUserNameErr("Please enter Address");
      return;
    }

    if (
      phoneNo === undefined ||
      phoneNo === "" ||
      (phoneNo != undefined && phoneNo.trim() === "")
    ) {
      formIsValid = false;
      setUserNameErr("Please enter phone number");
      return;
    }
    if (passportNo === undefined || passportNo === "") {
      formIsValid = false;
      setUserNameErr("Please enter passport number");
      return;
    }
    if (higestDegree === undefined || higestDegree === "") {
      formIsValid = false;
      setUserNameErr("Please enter higest degree");
      return;
    }
    if (workExperience === undefined || workExperience === "") {
      formIsValid = false;
      setUserNameErr("Please enter work experience");
      return;
    }

    if (formIsValid === true) {
      editUserProfile();
    }

    async function editUserProfile() {
      let profilepic;
      let document;
      if (profile.profilePhoto === "") {
        profilepic = profilePicName;
      } else {
        profilepic = profile.profilePhoto;
      }
      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          eid: userDetails.eid,
          uname: firstName,
          cno: phoneNo,
          ads: address,
          councode: countryCode,
          lstname: lastName,
          dob: dob,
          passportno: passportNo,
          othergovid: govtId,
          highdeg: higestDegree,
          workexp: workExperience,
          profilepicname: profilepic,
          docname: docList,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      console.log("bodyParam", bodyParam);

      setIsLoading(true);
      try {
        const response = await API.post(
          config.aws_cloud_logic_custom_name,
          "/updateuserprofile",
          bodyParam
          //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
        );
        const users = response;
        console.log("uresponse" + JSON.stringify(users));
        handleDeleteClose();

        if (deleteDocs === false) {
          swal({
            title: "Success",
            text: "Uploaded successfully",
            icon: "success",
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              setSpin(false);
            }
          });

          setSpin(false);
        }
        if (deleteDocs === true) {
          swal({
            title: "Success",
            text: "deleted successfully",
            icon: "success",
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              setSpin(false);
            }
          });

          setDeleteDocs(false);
        }
        setIsLoading(false);

        setSpin(false);
      } catch (error) {
        alert(error);
        console.log("getUserError", error);
      }
      console.log("Mount");
    }
  };

  const removeDocs = (message) => {
    let deleteMessageIndex = docList.indexOf(message);
    setDocList([
      ...docList.slice(0, deleteMessageIndex),
      ...docList.slice(deleteMessageIndex + 1),
    ]);

    setDeleteDocs(true);
  };

  async function getUsersDetails() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.eid,
        email: userDetails.email,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    setIsLoading(true);
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getUserProfile",
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );
      const users = response;
      console.log("uresponse" + JSON.stringify(users));

      setFirstName(users[0].UNAME);
      if (users[0].LASTNAME === undefined) {
        setLastName("");
      }

      console.log("LastName", users[0].LASTNAME);
      setLastName(users[0].LASTNAME);

      setEmail(users[0].EMAILID);
      setPhoneNo(users[0].CNO);
      setAddress(users[0].ADS);
      setDob(users[0].DOB);
      setHigestDegree(users[0].HIGHDEG);
      setCountryCode(users[0].COUNTRYCODE);
      setPassportNo(users[0].PASSPORTNO);
      setGovtId(users[0].OTHGOVID);
      setWorkExperience(users[0].WORKEXP);
      setProfilePicName(users[0].PROFILEPICNAME);
      console.log("users[0].DOCUMENTNAME", users[0].DOCUMENTNAME);

      if (
        users[0].DOCUMENTNAME === undefined ||
        users[0].DOCUMENTNAME.length === 0
      ) {
        setDocList([]);
      }
      if (users[0].MARKSCARD === undefined || users[0].MARKSCARD.length === 0) {
        setMarkFile([]);
      }
      if (
        users[0].CERTIFICATE === undefined ||
        users[0].CERTIFICATE.length === 0
      ) {
        setCertificateFile([]);
      }

      setDocList(users[0].DOCUMENTNAME);
      setMarkFile(users[0].MARKSCARD);
      setCookies(users[0].cookies);
      setCertificateFile(users[0].CERTIFICATE);
      setIsLoading(false);
    } catch (error) {
      console.log("getUserError", error);
    }

    console.log("docLIst", docList);
    console.log("Mount");
  }

  async function getUsn() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.eid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_USN,
        bodyParam
      );
      console.log("usn", response);

      //  setUsn(response);

      setPgm(userDetails.data.bpdata);
      const dataArray = Object.entries(response).map(([key, value]) => ({
        id: key,
        usn: value.usn,
      }));

      console.log("dataArray", dataArray);

      let a = [];

      for (let i = 0; i < userDetails.data.bpdata.length; i++) {
        for (let j = 0; j < dataArray.length; j++) {
          if (userDetails.data.bpdata[i].bpid == dataArray[j].id) {
            let data = {};

            if (dataArray[j].usn !== "") {
              data.pgName = userDetails.data.bpdata[i].ptitle;
              data.usn = dataArray[j].usn;
              console.log("data", data);

              //   setUsn((usn) => [...usn, data]);

              setUsn((usn) => [...usn, data]);
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <main>
      <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
        <FaBars />
      </div>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openAdd}
          closeAfterTransition
        >
          <Fade in={openAdd}>
            <ViewDoc
              handleAddClose={handleAddClose}
              docFileName={selectedFile}
              uploadType={uploadType}
              data={cookies}
              docCurlFileName={selectedCurlFile}
            />
          </Fade>
        </Modal>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openDelete}
          closeAfterTransition
        >
          <Fade in={openDelete}>
            <div
              style={{
                width: "fit-content",
                height: "fit-content",
                background: "transparent",
                alignitems: "center",
                justifycontent: "center",
                backgroundColor: "white",
                display: "grid",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  textAlign: "center",
                  margin: "10px",
                }}
              >
                <form>
                  <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                    Are you sure you want to delete ?
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "50px",
                      justifyContent: "center",
                      padding: "10px",
                    }}
                  >
                    <button
                      style={{ fontSize: "16px", fontWeight: "bold" }}
                      onClick={() => {
                        handleDeleteClose();
                        getUsersDetails();
                      }}
                    >
                      No
                    </button>
                    <button
                      onClick={onsubmit}
                      style={{ fontSize: "16px", fontWeight: "bold" }}
                    >
                      Yes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Fade>
        </Modal>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openDownload}
          closeAfterTransition
        >
          <Fade in={openDownload}>
            <DownloadDocs
              handleAddClose={handleDownloadClose}
              docFileName={selectedFile}
              uploadType={uploadType}
              data={cookies}
              docCurlFileName={selectedCurlFile}
            />
          </Fade>
        </Modal>

        <div>
          <UserHeader />
          <div
            className="container"
            style={{
              alignItems: "center",
              marginTop: "30px",
              backgroundColor: "white",
              boxShadow: "0px 0px 5px 0px lightgrey ",
            }}
          >
            <h3
              style={{
                color: "black",
                fontSize: "20px",
                paddingTop: "30px",
                paddingBottom: "-10px",
                textAlign: "center",
                marginBottom: "15px",
              }}
            >
              My Profile
            </h3>

            <hr
              style={{
                width: "40%",
                margin: "auto",
                borderColor: "#3372b5",
                borderWidth: "3px",
              }}
            />
            <div style={{ textAlign: "center", marginTop: "2rem" }}>
              {/* <form style={{ position: "relative" }}> */}
              <input
                type="file"
                id="pfiles"
                name="file"
                accept="image/*"
                required
                style={{
                  width: "15rem",
                  height: "4rem",
                  display: "none",
                }}
                onChange={(e) => {
                  handleProfilePhoto(e);
                }}
              />

              {refselectedCourseFile.current === undefined && (
                <div>
                  <Avatar
                    style={{
                      cursor: "pointer",
                      height: "12rem",
                      width: "12rem",
                      margin: "auto",
                    }}
                    src={`https://${Constants.DOMAIN
                      }/${config.aws_org_id.toLowerCase()}-resources/profile/${userDetails.eid
                      }/profilepic/${profilePicName}`}
                  />

                  <label
                    for="pfiles"
                    style={{
                      position: "relative",
                      bottom: "2rem",
                      left: "3rem",
                    }}
                  >
                    <BorderColorIcon
                      style={{
                        cursor: "pointer",
                        height: "20px",
                        width: "20px",
                      }}
                    />
                  </label>
                </div>
              )}
              {refselectedCourseFile.current !== undefined && (
                <div>
                  <img
                    style={{
                      width: "12rem",
                      margin: "auto",
                      height: "12rem",
                      borderRadius: "100%",
                    }}
                    src={URL.createObjectURL(refselectedCourseFile.current)}
                    alt="courseimage"
                  />

                  <label
                    for="pfiles"
                    style={{
                      position: "relative",
                      top: "4rem",
                      right: "3rem",
                    }}
                  >
                    <BorderColorIcon
                      style={{
                        cursor: "pointer",
                        height: "20px",
                        width: "20px",
                      }}
                    />
                  </label>
                </div>
              )}

              {/* </form> */}
            </div>

            <div className={classes.formContainer}>
              <div className={classes.nameSection}>
                <div className="User_field">
                  <label
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "grey",
                    }}
                  >
                    First Name
                  </label>

                  <br />

                  {isLoading ? (
                    <>
                      <Typography
                        component="h1"
                        variant="h3"
                        className="loadingcss"
                      >
                        <Skeleton animation="wave" />
                      </Typography>
                    </>
                  ) : (
                    <input
                      className={classes.inputFieldName}
                      type="text"
                      id="first_name"
                      value={firstName}
                      onChange={onChangeHandlerUser}
                    />
                  )}
                </div>
                <div className="User_inputfield">
                  <label
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "grey",
                    }}
                  >
                    Last Name
                  </label>
                  <br />
                  {isLoading ? (
                    <>
                      <Typography
                        component="h1"
                        variant="h3"
                        className="loadingcss"
                      >
                        <Skeleton animation="wave" />
                      </Typography>
                    </>
                  ) : (
                    <input
                      className={classes.inputFieldName}
                      type="text"
                      id="last_name"
                      onChange={onChangeHandlerLastName}
                      value={lastName}
                    />
                  )}
                </div>
              </div>
              <div
                className="User_field"
                style={{
                  marginTop: "20px",
                }}
              >
                <label
                  style={{
                    fontSize: "16px",

                    fontWeight: "bold",
                    color: "grey",
                  }}
                >
                  Email
                </label>
              </div>
              <div className="User_inputfield" style={{ marginBottom: "20px" }}>
                {isLoading ? (
                  <>
                    <Typography
                      component="h1"
                      variant="h3"
                      className="loadingcss"
                    >
                      <Skeleton animation="wave" />
                    </Typography>
                  </>
                ) : (
                  <input
                    className={classes.textFieldNormal}
                    type="email"
                    id="email"
                    value={email}
                    disabled
                  />
                )}
              </div>

              <div
                className="User_field"
                style={{
                  marginTop: "20px",
                }}
              >
                <label
                  style={{
                    fontSize: "16px",

                    fontWeight: "bold",
                    color: "grey",
                  }}
                >
                  Candidate Id
                </label>
              </div>
              <div className="User_inputfield" style={{ marginBottom: "20px" }}>
                {isLoading ? (
                  <>
                    <Typography
                      component="h1"
                      variant="h3"
                      className="loadingcss"
                    >
                      <Skeleton animation="wave" />
                    </Typography>
                  </>
                ) : (
                  <div>
                    {usn.length != 0 || usn.length != undefined ? (
                      <>
                        {usn.map((pgm, index) => (
                          <div
                            //className="User_field"
                            style={{
                              marginTop: "10px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label
                              style={{
                                fontSize: "16px",

                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              {pgm.pgName}
                            </label>

                            <input
                              // className={classes.textFieldNormal}

                              style={{
                                border: "none",
                                height: "25px",
                                width: "50%",
                                boxShadow: "0px 0px 5px 0px lightgrey ",
                                fontSize: "15px",
                                fontWeight: "bold",
                                padding: "5px",
                              }}
                              value={pgm.usn}
                              disabled
                            />
                          </div>
                        ))}
                      </>
                    ) : (
                      <>
                        <input
                          // className={classes.textFieldNormal}

                          style={{
                            border: "none",
                            height: "25px",
                            width: "50%",
                            boxShadow: "0px 0px 5px 0px lightgrey ",
                            fontSize: "15px",
                            fontWeight: "bold",
                            padding: "5px",
                          }}
                          value="No USN"
                          disabled
                        />
                      </>
                    )}
                  </div>
                  // <input
                  //   className={classes.textFieldNormal}
                  //   type="email"
                  //   id="email"
                  //   value={email}
                  //   disabled
                  // />
                )}
              </div>
              <div className="User_field">
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "grey",
                  }}
                >
                  Phone number
                </label>
              </div>
              <div className="User_inputfield" style={{ marginBottom: "20px" }}>
                {isLoading ? (
                  <>
                    <Typography
                      component="h1"
                      variant="h3"
                      className="loadingcss"
                    >
                      <Skeleton animation="wave" />
                    </Typography>
                  </>
                ) : (
                  <input
                    className={classes.textFieldNormal}
                    type="number"
                    id="ph_no"
                    value={phoneNo}
                    onChange={onChangePhoneNo}
                  />
                )}
              </div>
              <div className={classes.responsiveFieldSection}>
                <div className="User_field">
                  <label
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "grey",
                    }}
                  >
                    Country Code
                  </label>
                  <br />
                  {isLoading ? (
                    <>
                      <Typography
                        component="h1"
                        variant="h3"
                        className="loadingcss"
                      >
                        <Skeleton animation="wave" />
                      </Typography>
                    </>
                  ) : (
                    <input
                      className={classes.inputFieldName}
                      type="number"
                      id="Country_Code"
                      onChange={onChangeHandlerCountryCode}
                      value={countryCode}
                    />
                  )}
                </div>

                <div className="User_inputfield">
                  <label
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "grey",
                    }}
                  >
                    Date of Birth
                  </label>

                  <br />

                  {isLoading ? (
                    <>
                      <Typography
                        component="h1"
                        variant="h3"
                        className="loadingcss"
                      >
                        <Skeleton animation="wave" />
                      </Typography>
                    </>
                  ) : (
                    <input
                      className={classes.inputFieldName}
                      type="date"
                      max="2010-01-01"
                      id="DOB"
                      value={dob}
                      onChange={onChangeHandlerDob}
                    />
                  )}
                </div>
              </div>

              <div className="User_field">
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "grey",
                  }}
                >
                  Address
                </label>
              </div>

              <div className="User_inputfield" style={{ marginBottom: "20px" }}>
                {isLoading ? (
                  <>
                    <Typography
                      component="h1"
                      variant="h3"
                      className="loadingcss"
                    >
                      <Skeleton animation="wave" />
                    </Typography>
                  </>
                ) : (
                  <textarea
                    rows="5"
                    cols="60"
                    name="description"
                    style={{ height: "75px" }}
                    className={classes.textFieldNormal}
                    value={address}
                    type="text"
                    id="address"
                    onChange={onChangeHandlerAddress}
                  ></textarea>
                )}
              </div>

              <div className={classes.responsiveFieldSection}>
                <div className="User_field">
                  <label
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "grey",
                    }}
                  >
                    Passport Number
                  </label>

                  <br />

                  {isLoading ? (
                    <>
                      <Typography
                        component="h1"
                        variant="h3"
                        className="loadingcss"
                      >
                        <Skeleton animation="wave" />
                      </Typography>
                    </>
                  ) : (
                    <input
                      className={classes.inputFieldName}
                      type="text"
                      id="passport_no"
                      onChange={onChangeHandlerPassportNo}
                      value={passportNo}
                    />
                  )}
                </div>
                <div className="User_inputfield">
                  <label
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "grey",
                    }}
                  >
                    Other Govt ID Number
                  </label>

                  <br />

                  {isLoading ? (
                    <>
                      <Typography
                        component="h1"
                        variant="h3"
                        className="loadingcss"
                      >
                        <Skeleton animation="wave" />
                      </Typography>
                    </>
                  ) : (
                    <input
                      className={classes.inputFieldName}
                      type="text"
                      id="other_gid"
                      onChange={onChangeHandlerGovtId}
                      value={govtId}
                    />
                  )}
                </div>
              </div>
              <div className="User_field">
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "grey",
                  }}
                >
                  Higest Degree
                </label>
              </div>

              <div className="User_inputfield" style={{ marginBottom: "20px" }}>
                {isLoading ? (
                  <>
                    <Typography
                      component="h1"
                      variant="h3"
                      className="loadingcss"
                    >
                      <Skeleton animation="wave" />
                    </Typography>
                  </>
                ) : (
                  <input
                    className={classes.textFieldNormal}
                    type="text"
                    id="higest_degree"
                    onChange={onChangeHandlerHigestDegree}
                    value={higestDegree}
                  />
                )}
              </div>
              <div className="User_field">
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "grey",
                  }}
                >
                  Work Experience
                </label>
              </div>
              <div
                className="User_inputfield"
                style={{ marginBottom: "20px", display: "flex" }}
              >
                {isLoading ? (
                  <>
                    <Typography
                      component="h1"
                      variant="h3"
                      className="loadingcss"
                    >
                      <Skeleton animation="wave" />
                    </Typography>
                  </>
                ) : (
                  <textarea
                    rows="5"
                    cols="60"
                    name="description"
                    style={{ height: "75px" }}
                    className={classes.textFieldNormal}
                    id="work_experience"
                    onChange={onChangeHandlerWorkExperience}
                    value={workExperience}
                  ></textarea>
                )}

                {/* <input
                  style={{
                    border: "none",
                    width: "100%",
                    height: "75px",
                    boxShadow: "0px 0px 5px 0px lightgrey ",
                    fontSize: "15px",
                  }}
                  id="work_experience"
                  onChange={onChangeHandlerLastName}
                /> */}
              </div>
              <div
                className="User_field"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "grey",
                  }}
                >
                  Uploaded Documents
                </label>
                <h4
                  style={{
                    color: "red",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {pissueTextUpload}
                </h4>
                <label
                  for="document"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <FileUploadIcon
                    style={{
                      width: "20px",
                      height: "20px",
                      cursor: "pointer",
                      color: "blue",
                    }}
                  />
                </label>
              </div>
              <div className="User_inputfield" style={{ marginBottom: "20px" }}>
                {isLoading ? (
                  <>
                    <Typography
                      component="h1"
                      variant="h3"
                      className="loadingcss"
                    >
                      <Skeleton animation="wave" />
                    </Typography>
                  </>
                ) : (
                  <div
                    style={{
                      border: "none",
                      width: "100%",
                      boxShadow: "0px 0px 5px 0px lightgrey ",
                      fontSize: "15px",
                      fontWeight: "bold",
                      backgroundColor: "white",
                      justifyContent: "space-between",
                    }}
                  >
                    <input
                      type="file"
                      id="document"
                      name="file"
                      accept=".pdf"
                      required
                      style={{
                        display: "none",
                      }}
                      onChange={(e) => {
                        handleProfileDocument(e);
                      }}
                    />

                    <div>
                      {docList === undefined ||
                        docList.length === 0 ||
                        docList.length === undefined ? (
                        <div>No Documents</div>
                      ) : (
                        docList.map((docs, idx) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <label style={{ width: "70%" }}> {docs}</label>

                              <div>
                                <VisibilityIcon
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    marginRight: "15px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setSelectedFile(docs);
                                    handleAddOpen();
                                    setUploadType("profiledocs");
                                  }}
                                />
                                <HighlightOffIcon
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    cursor: "pointer",
                                    color: "red",
                                  }}
                                  key={idx}
                                  onClick={() => {
                                    removeDocs(docs);
                                    handledeleteOpen();
                                    setUploadType("profiledocs");
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="User_field">
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "grey",
                  }}
                >
                  Certificates
                </label>
              </div>
              <div
                className="User_inputfield"
                style={{
                  marginBottom: "20px",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {isLoading ? (
                  <>
                    <Typography
                      component="h1"
                      variant="h3"
                      className="loadingcss"
                    >
                      <Skeleton animation="wave" />
                    </Typography>
                  </>
                ) : (
                  <div
                    style={{
                      border: "none",
                      width: "100%",
                      boxShadow: "0px 0px 5px 0px lightgrey ",
                      fontSize: "15px",
                      fontWeight: "bold",
                      backgroundColor: "white",
                    }}
                  >
                    <div>
                      {certificateFile === undefined ||
                        certificateFile.length === 0 ? (
                        <div>No Certificate</div>
                      ) : (
                        Array.isArray(certificateFile) &&
                        certificateFile.map((docs, idx) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <label style={{ width: "70%" }}> {docs}</label>
                              <div>
                                <VisibilityIcon
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    marginRight: "15px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setSelectedFile(docs);
                                    handleAddOpen();
                                    setUploadType("certificate");
                                    setSelectedCurlFile(`https://${Constants.DOMAIN}/${config.aws_org_id.toLowerCase()}-data/data/certificates/${userDetails.email}/${docs}`);
                                  }}
                                />

                                <DownloadIcon
                                  onClick={() => {
                                    setSelectedFile(docs);
                                    handleDownloadOpen();
                                    setUploadType("certificate");
                                  }}
                                  style={{
                                    color: "blue",
                                    width: "20px",
                                    height: "20px",
                                    marginRight: "15px",
                                    cursor: "pointer",
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="User_field">
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "grey",
                  }}
                >
                  Marks card
                </label>
              </div>

              <div className="User_inputfield">
                {isLoading ? (
                  <>
                    <Typography
                      component="h1"
                      variant="h3"
                      className="loadingcss"
                    >
                      <Skeleton animation="wave" />
                    </Typography>
                  </>
                ) : (
                  <div
                    style={{
                      border: "none",
                      width: "100%",
                      boxShadow: "0px 0px 5px 0px lightgrey ",
                      fontSize: "15px",
                      fontWeight: "bold",
                      backgroundColor: "white",
                    }}
                  >
                    <div>
                      {marksFile === undefined || marksFile.length === 0 ? (
                        <div>No MarksCard</div>
                      ) : (
                        <>
                          {Array.isArray(marksFile) &&
                            marksFile.length > 0 &&
                            marksFile.map((mfile) => {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <label style={{ width: "70%" }}>
                                    {" "}
                                    {mfile.file}
                                  </label>
                                  <div>
                                    <VisibilityIcon
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        marginRight: "15px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setSelectedFile(mfile.file);
                                        setSelectedCurlFile(mfile.curl);
                                        handleAddOpen();
                                        setUploadType("markscardlist");
                                      }}
                                    />

                                    <DownloadIcon
                                      style={{
                                        color: "blue",
                                        width: "20px",
                                        height: "20px",
                                        marginRight: "15px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setSelectedFile(mfile.file);
                                        setSelectedCurlFile(mfile.curl);
                                        handleDownloadOpen();
                                        setUploadType("markscardlist");
                                      }}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div
                className="Submit_button"
                style={{ marginTop: "30px", textAlign: "center" }}
              >
                <div style={{ color: "red", fontSize: "14px" }}>
                  {" "}
                  {userNameErr}
                </div>
                <button
                  style={{
                    backgroundColor: "#FF7D00",
                    width: "100px",
                    height: "40px",
                    fontSize: "14px",
                    border: "none",
                    color: "white",
                    marginBottom: "2rem",
                    borderRadius: "5px",
                  }}
                  onClick={onsubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Profile;
