import React, { useEffect } from "react";
import { Auth } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route, Link } from "react-router-dom";
import { authData, awsSignOut } from "../redux/auth/authSlice";
import LandingScreen from "../components/LandingScreen/LandingScreen";
import EventLayout from "../Layouts/EventLayout";
import MyApplicationLayout from "../Layouts/MyApplicationLayout";
import MyProgramsPreLogin from "../components/MyProgramsPreLogin/MyProgramsPreLogin";
import "./App.scss";
import Layout from "../components/HomeScreen/Dashboard/Layout";
import MyProgramsLayout from "../Layouts/MyProgramsLayout";
import CourseLayout from "../Layouts/CourseLayout";
import CoursePlayerLayout from "../Layouts/CoursePlayerLayout";
import IndividualProgramLayout from "../Layouts/IndividualProgramLayout";
import NotificationLayout from "../Layouts/NotificationLayout";
import ReportsAndAnalyticsLayout from "../Layouts/ReportsAndAnalyticsLayout";
import SyllabusLayout from "../Layouts/SyllabusLayout";
import AnnouncementsLayout from "../Layouts/AnnouncementsLayout";
import AcademicScheduleLayout from "../Layouts/AcademicScheduleLayout";
import ProgramInfoLayout from "../Layouts/ProgramInfoLayout";
import OnlineProgram from "../modules/TopNav_StaticPages/Admissions/OnlineProgram";
import AboutJSSAHER from "../modules/TopNav_StaticPages/AboutUs/AboutJSSAHER";
import AboutOnlineLearning from "../modules/TopNav_StaticPages/AboutUs/OnlineLearning";
import ContactUs from "../modules/TopNav_StaticPages/ContactUs/ContactUs";
import AllProgramsLayout from "../Layouts/AllProgramsLayout";
import AllProgramsInfoLayout from "../Layouts/ApplyProgramInfoLayout";
import ApplicationLayout from "../Layouts/ApplicationLayout";
import JssApplicationLayout from "../Layouts/JssApplicationLayout";
import QuickTourPreLogin from "../components/QuickTour/QuickTourPreLogin";
import QuickTourLayout from "../Layouts/QuickTourLayout";
import ApplicationPre from "../components/MyProgramsPreLogin/ApplicationPre";
import ProfileLayout from "../Layouts/ProfileLayout";
import PostQuires from "../components/PostQuries/PostQuires";
import HelpLayout from "../Layouts/HelpLayout";
import ComplaintsLayout from "../Layouts/ComplaintsLayout";
import SharedObject from "../components/SharedObject/SharedObject";
import ViewAllLayout from "../Layouts/ViewAllLayout";
import AnnouncementSidenavLayout from "../Layouts/AnnouncementSidenavLayout";
import TestLayout from "../Layouts/TestLayout";
import TestResultLayout from "../Layouts/TestResultLayout";
import CertificateLayout from "../Layouts/CertificateLayout";

function App() {
  const userAuthStateValue = useSelector(authData);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => { };
  }, []);

  function About() {
    return (
      <div>
        <button
          onClick={() => {
            Auth.signOut();
            dispatch(awsSignOut());
          }}
        >
          signOut
        </button>
        <h1>About</h1>
        <nav>
          <Link to="/">Home</Link> {" | "}
          <Link to="/LandingScreen">LandingScreen</Link> {" | "}
          <Link to="/about">About</Link>
        </nav>
      </div>
    );
  }

  return (
    <div className="App">
      <Routes>
        {userAuthStateValue === 0 ? (
          <>
            <Route path="/" element={<LandingScreen />} />
            <Route path="*" element={<LandingScreen />} />
            <Route path="/onlineProgram" element={<OnlineProgram />} />
            <Route path="/aboutJssaher" element={<AboutJSSAHER />} />
            <Route path="/aboutOEP" element={<AboutOnlineLearning />} />
            <Route path="/contactUs" element={<ContactUs />} />
            <Route path="/myProg/:pid/:il" element={<MyProgramsPreLogin />} />
            <Route path="/quickTourPreLogin" element={<QuickTourPreLogin />} />
            <Route path="/application" element={<ApplicationPre />} />
            <Route path="/postquires" element={<PostQuires />} />
            <Route path="/sharingobject" element={<SharedObject />} />
          </>
        ) : (
          <>
            <Route path="/quickTour" element={<QuickTourLayout />} />
            <Route path="/" element={<Layout />} />
            <Route path="/dashboard" element={<Layout />} />
            <Route path="/contactUs" element={<ContactUs />} />
            <Route path="/onlineProgram" element={<OnlineProgram />} />
            <Route path="/aboutJssaher" element={<AboutJSSAHER />} />
            <Route path="/aboutOEP" element={<AboutOnlineLearning />} />
            <Route path="/LandingScreen" element={<LandingScreen />} />
            <Route path="about" element={<About />} />

            <Route
              path="/MyPrograms/programs"
              element={<IndividualProgramLayout />}
            />

            <Route path="/myPrograms" element={<MyProgramsLayout />} />
            <Route path="/course" element={<CourseLayout />} />
            <Route path="/coursePlayer" element={<CoursePlayerLayout />} />
            <Route path="/myApplication" element={<MyApplicationLayout />} />
            <Route path="/myProg" element={<MyProgramsPreLogin />} />
            <Route path="/profile" element={<ProfileLayout />} />
            <Route path="/addComplaints" element={<HelpLayout />} />
            <Route path="/complaints" element={<ComplaintsLayout />} />
            <Route path="/event" element={<EventLayout />} />
            <Route path="/notification" element={<NotificationLayout />} />
            <Route
              path="/reportsAndAnalytics"
              element={<ReportsAndAnalyticsLayout />}
            />
            <Route
              path="myPrograms/programinfo"
              element={<ProgramInfoLayout />}
            />
            <Route path="/myPrograms/syllabus" element={<SyllabusLayout />} />
            <Route
              path="/myPrograms/announcements"
              element={<AnnouncementsLayout />}
            />
            <Route
              path="/myPrograms/academicSchedule"
              element={<AcademicScheduleLayout />}
            />
            <Route path="/allPrograms" element={<AllProgramsLayout />} />
            <Route path="/applyProgram" element={<AllProgramsInfoLayout />} />
            <Route
              path="/myApplication/application"
              element={<ApplicationLayout />}
            />
            <Route
              path="/myApplication/jssapplication"
              element={<JssApplicationLayout />}
            />
            <Route path="/viewall" element={<ViewAllLayout />} />
            <Route
              path="/announcements"
              element={<AnnouncementSidenavLayout />}
            />
            <Route
              path="/tests"
              element={<TestLayout />}
            />
            <Route
              path="/testresult"
              element={<TestResultLayout />}
            />

            <Route
              path="/certificates"
              element={<CertificateLayout />}
            />

            <Route path="*" element={<Layout />} />
          </>
        )}
      </Routes>
    </div>
  );
}

export default App;
