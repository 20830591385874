// Dependencies imports
import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import { API } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import swal from "sweetalert";
// Style imports
// import Rec from "./Record.module.scss";
import { awsSignIn, authData, awsSignOut } from "../../redux/auth/authSlice";
// import ReferencePopUp from './ReferencePopUp';
import assessStyle from "../Assignment/Assignment.module.scss";
import landingStyle from "../../components/LandingScreen/landingScreen.module.scss";

// Styles for Tables
const useStyles = makeStyles((theme) => ({
  modal: {
    height: "78%",
    width: "60%",
    backgroundColor: "white",
    '@media (max-width: 770px)': {
      width: "90%",
    },
    '@media (min-height: 800px)': {
      height: 'fit-content!important',
      padding: '2rem 0rem',
    },
  },
  heading: {
    margin: "0 3rem",
    padding: "1rem 0",
    '@media (max-width: 770px)': {
      margin: "0 2rem",
      padding: "1rem 0",
    },
  },
  
}));

// Start of RecordSession Component
const Feedback = (props) => {
  let { handleClose, Value } = props;
  let userDetails = useSelector(authData);
  const [openSession, setOpenSession] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [questionNumber, setQuestionNumber] = useState();
  const [feedbackQuestions, setFeedbackQuestions] = useState();
  const [loaded, setLoaded] = useState(true);
  const [userReponse, setUserReponse] = useState([]);
  const [responseCode, setResponseCode] = useState();
  const classes = useStyles();

  useEffect(() => {
    // alert(JSON.stringify(Value))
    getFeedback();
  }, []);

  async function getFeedback() {
    setLoaded(true);
    const bodyParam = {
      body: {
        pid: Value.pid,
        bpid: Value.bpid,
        oid: config.aws_org_id,
        eid: userDetails.eid,
        // pid: "009",
        // bpid: "0090048006",
        // oid: "JSSAHER"
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    // alert(JSON.stringify(bodyParam.body))
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_FEEDBACK,
        bodyParam
      );
      // alert(JSON.stringify(response));
      if (response == {} || response == null || response == undefined) {
        // alert("0")
        setResponseCode(0);
        setFeedbackQuestions(null);
        setLoaded(false);
      }
      if (

        response === null ||
        response === undefined ||
        response.code === 0 ||
        response.code === "0" ||
        response.code === 1 ||
        response.code === "1"
      ) {
        // alert("0")
        setResponseCode(response.code);
        setFeedbackQuestions(null);
        setLoaded(false);
      } else {
        // alert(JSON.stringify(response.questions[0]))
        setFeedbackQuestions(response.questions);
        setLoaded(false);
      }
      setLoaded(false);
    } catch (error) {
      console.error(error);
    }
  }

  const handleAnswerOptionClick = (value) => {
    // alert(value)
    userReponse.push(value);
    const nextQuestion = currentQuestion + 1;

    if (nextQuestion < feedbackQuestions.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      swal({
        title: "Success",
        text: " Feedback submitted successfully",
        icon: "success",
        dangerMode: false,
      }).then((willDelete) => {
        if (willDelete) {
          setLoaded(false);
        }
      });
      answerObject(userReponse);
      handleClose();
    }
  };

  function answerObject(value) {
    // userReponse.push(value);
    updateFeedback(userReponse);
  }

  async function updateFeedback(userReponse) {
    let lcourseDetails = userDetails.curprgcou;
    // alert(JSON.stringify(userReponse))
    setLoaded(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        response: userReponse,
        obid: Value.bpid,
        eid: userDetails.eid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    // alert(JSON.stringify(bodyParam.body))
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.UPDATE_FEEDBACK,
        bodyParam
      );
      // alert(JSON.stringify(response))
      setLoaded(false);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className={classes.modal}>
      {loaded === false ? (
        <div>
          <div>
            <span
              style={{
                float: "right",
                cursor: "pointer",
                color: "black",
                fontSize: "26px",
                paddingRight: "20px",
                // paddingBottom: "20px",
              }}
              onClick={() => {
                handleClose();
              }}
            >
              x
            </span>
          </div>
          <h2 className={classes.heading}>
            Dear Learner, Your feedback is extremely valuable for the
            University.It helps the University to improve its academic offerings
            continuously.
          </h2>
          <h2 className={classes.heading}>
            We request you to share feedback about the program you enrolled for.
          </h2>
          <div style={{ width: "100%" }}>
            {feedbackQuestions !== null ? (
              <div className={assessStyle.questiontextSurvey}>
                {/* {(feedbackQuestions.map((questions, index) => ( */}
                {/* // {setQuestionNumber(index)} */}
                <div>
                  <div className="question-count">
                    <span>Question {currentQuestion + 1}</span>/
                    {feedbackQuestions.length}
                  </div>
                  <div>
                    <p>
                      {currentQuestion + 1} {feedbackQuestions[currentQuestion]}
                    </p>
                  </div>
                  <div
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      flex: 1,
                    }}
                  >
                    <div className={assessStyle.btncheck}>
                      <input
                        type="radio"
                        className="btn-check"
                        name="options"
                      />
                      <label
                        className={
                          assessStyle.feedbackstyle +
                          " " +
                          assessStyle.FeedbackOption
                        }
                        onClick={() => handleAnswerOptionClick(0)}
                      >
                        Strongly Agree
                      </label>
                    </div>
                    <div className={assessStyle.btncheck}>
                      <input
                        type="radio"
                        className="btn-check"
                        name="options"
                      />
                      <label
                        className={
                          assessStyle.feedbackstyle +
                          " " +
                          assessStyle.FeedbackOption
                        }
                        onClick={() => handleAnswerOptionClick(1)}
                      >
                        Agree
                      </label>
                    </div>
                    <div className={assessStyle.btncheck}>
                      <input
                        type="radio"
                        className="btn-check"
                        name="options"
                      />
                      <label
                        className={
                          assessStyle.feedbackstyle +
                          " " +
                          assessStyle.FeedbackOption
                        }
                        onClick={() => handleAnswerOptionClick(2)}
                      >
                        Disagree
                      </label>
                    </div>
                    <div className={assessStyle.btncheck}>
                      <input
                        type="radio"
                        className="btn-check"
                        name="options"
                      />
                      <label
                        className={
                          assessStyle.feedbackstyle +
                          " " +
                          assessStyle.FeedbackOption
                        }
                        onClick={() => handleAnswerOptionClick(3)}
                      >
                        Strongly Disagree
                      </label>
                    </div>
                    <div className={assessStyle.btncheck}>
                      <input
                        type="radio"
                        className="btn-check"
                        name="options"
                      />
                      <label
                        className={
                          assessStyle.feedbackstyle +
                          " " +
                          assessStyle.FeedbackOption
                        }
                        onClick={() => handleAnswerOptionClick(4)}
                      >
                        Not Applicable
                      </label>
                    </div>
                  </div>
                </div>
                {/* )))} */}
              </div>
            ) : (
              <div>
                {responseCode == 0 || responseCode == "0" ? (
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <h1
                      style={{
                        margin: "10%",
                      }}
                    >
                      Feedback is disabled from Admin
                    </h1>
                  </div>
                ) : (
                  <div>
                    <div
                      style={{
                        width: "100%",
                      }}
                    >
                      <h1
                        style={{
                          // display: "flex",
                          width: "100%",
                          // height: "100%",
                          // justifyContent: "center",
                          // alignItems: "center",
                          marginTop: "30%",
                          marginLeft: "20%",
                        }}
                      >
                        Feedback already submitted
                      </h1>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <CircularProgress
          color="default"
          size={30}
          className={landingStyle.spinnerStyle}
        />
      )}
    </div>
  );
};

export default Feedback;
