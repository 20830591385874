import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CardMedia } from "@material-ui/core";
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import { setCookie } from "react-use-cookie";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
}));

const ViewDoc = ({ handleAddClose, docFileName, uploadType, data, docCurlFileName }) => {
  const classes = useStyles();
  const userDetails = useSelector(authData);
  const ext = docFileName.split(".").pop(); // Get file extension

  if (data !== "") {
    const expires = new Date().getTime() + 60 * 60 * 1000;
    setCookie("CloudFront-Expires", expires);
    setCookie("CloudFront-Policy", data.Policy);
    setCookie("CloudFront-Signature", data.Signature);
    setCookie("CloudFront-Key-Pair-Id", data.KeyPairId);
  }

  const isPDF = ext === "pdf";


  console.log("docCurlFileName", docCurlFileName)

  return (
    <div style={{ width: "80%", background: "transparent", backgroundColor: "white", display: "grid" }}>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", height: "max-content" }}>
        <h3 style={{ padding: "10px" }}>{docFileName}</h3>
        {/* <button onClick={handleAddClose}>X</button> */}
        <IconButton

          component="span"
          onClick={() => {
            handleAddClose();
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div style={{ textAlign: "center", marginTop: "20px", height: "50rem", overflow: "scroll", overflowX: "hidden" }}>
        {docCurlFileName === undefined ? (
          <CardMedia
            style={{ height: "100%", width: "100%" }}
            className={classes.root}
            alt="ProfilePage"
            component="iframe"
            src={`https://${Constants.DOMAIN}/${config.aws_org_id.toLowerCase()}-resources/profile/${userDetails.eid}/${uploadType}/${docFileName}`}
          />
        ) : isPDF ? (
          <CardMedia
            style={{ height: "100%", width: "100%" }}
            className={classes.root}
            alt="ProfilePage"
            component="iframe"
            src={docCurlFileName}
          />
        ) : (
          <div>
            <img src={docCurlFileName} alt={docFileName} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewDoc;
